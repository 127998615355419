var markjs = require('mark.js');
var tippyjs = require('tippy.js').default;
module.exports = function (options) {
  return {
    $: function(selector){
      return jQuery(selector, this.$el)
    },
    initialize: function (options) {
      var that = this;
      this.$el = jQuery(options.el);
      this.el = options.el;
      this.glossar = jQuery.ajax(window.tpdata.glossar_ep, {
        success: function (res) {
          that.setup(res.posts)
        }
      });
    },
    setup: function (posts) {
      var that = this;
      this.glossar = posts;
      this.Mark = new markjs(this.el);
      this.glossar.map(item =>  {

        that.Mark.mark(item.title + '.', {
          accuracy: 'exactly',
          separateWordSearch: false,
          ignorePunctuation: [],
          filter: function(node, range, match, found) {
            let alreadyMarked = window.markedItems.filter(i => i === item.title);
            if (alreadyMarked.length === 0) {
              window.markedItems.push(item.title);
              return true;
            }
            return false;
          },
          each: function (el) {
            tippyjs(el, {
              content: item.description,
              allowHTML: true,
              trigger: 'click',
            });
          }
        })

        that.Mark.mark(item.title + ',', {
          accuracy: 'exactly',
          separateWordSearch: false,
          ignorePunctuation: [],
          filter: function(node, range, match, found) {
            let alreadyMarked = window.markedItems.filter(i => i === item.title);
            if (alreadyMarked.length === 0) {
              window.markedItems.push(item.title);
              return true;
            }
            return false;
          },
          each: function (el) {
            tippyjs(el, {
              content: item.description,
              allowHTML: true,
              trigger: 'click',
            });
          }
        })

        that.Mark.mark(item.title.trim(), {
          accuracy: 'exactly',
          separateWordSearch: false,
          ignorePunctuation: [",","."],
          filter: function(node, range, match, found) {
            let alreadyMarked = window.markedItems.filter(i => i === item.title);
            if (alreadyMarked.length === 0) {
              window.markedItems.push(item.title);
              return true;
            }
            return false;
          },
          each: function (el) {
            tippyjs(el, {
              content: item.description,
              allowHTML: true,
              trigger: 'click',
            });
          }
        })

        if (item.aliases.length > 0) {
         item.aliases.map(a => {
            that.Mark.mark(a.trim(), {
              accuracy: 'exactly',
              separateWordSearch: false,
              ignorePunctuation: [",","."],
              filter: function(node, range, match, found) {
                let alreadyMarked = window.markedItems.filter(i => i === item.title);
                if (alreadyMarked.length === 0) {
                  window.markedItems.push(item.title);
                  return true;
                }
                return false;
              },
              each: function (el) {
                tippyjs(el, {
                  content: item.description,
                  allowHTML: true,
                  trigger: 'click'
                });
              }
            })
           that.Mark.mark(a.trim() + ',', {
             accuracy: 'exactly',
             separateWordSearch: false,
             ignorePunctuation: [",","."],
             filter: function(node, range, match, found) {
               let alreadyMarked = window.markedItems.filter(i => i === item.title);
               if (alreadyMarked.length === 0) {
                 window.markedItems.push(item.title);
                 return true;
               }
               return false;
             },
             each: function (el) {
               tippyjs(el, {
                 content: item.description,
                 allowHTML: true,
                 trigger: 'click'
               });
             }
           })
          })
        }


      })

    }
  }
};