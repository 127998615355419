import $ from 'jquery';
import LoadMore from './components/KDReadMore';
import Filter from './components/KDFilter';
import FilterTypeahead from './components/KDFilterTypeahead';
import Glossar from './components/Glossar.js';
import PostsFilter from './components/postsfilter.js';
import Lazyembed from './components/lazyembed.js';

window.jQuery = $;
window.$ = $;
var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
import 'what-input';


// (function (w, d, s, l, i) {
//
//   // var el = document.getElementById('optanon-wrapper');
//   w[l] = w[l] || [];
//   w[l].push({
//     'gtm.start':
//       new Date().getTime(), event: 'gtm.js'
//   });
//   var f = d.getElementsByTagName(s)[0],
//
//     j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
//   // j.setAttribute('nonce', el.getAttribute('data-nonce'));
//   // j.nonce = el.getAttribute('data-nonce');
//   j.async = true;
//   j.defer = true;
//   j.src =
//
//     'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
//   f.parentNode.insertBefore(j, f);
//
// })(window, document, 'script', 'dataLayer', 'GTM-PD4H6R');

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

require("./lib/slick.min.js");
require("./lib/mouse.parallax.js");
require("./lib/js-cookie.js");
require('./components/navigation.js');
require('./components/topicsnav.js');
require("./components/slider.js");
require("./components/menu.js");
require("./components/animation.js");
require("./components/overlay.js");
require("./components/content.js");
require("./components/form.js");
require("./components/header.js");
const Suggest = require("./components/autosuggest.js");

Suggest.initialize();

$('#background').mouseParallax({moveFactor: 2});

// var sliced = $('.tag').slice(10).detach();
// var $tagToggle = $('<div class="js-tag-more"></div><br><span class="tag-toggle js-tag-toggle">Alle Kategorien</span>').appendTo('.filter-tags');
// $('.js-tag-more').append(sliced).slideUp();
// $('.js-tag-toggle').on('click', function () {
// 	var text = $(this).text();
// 	if($('.js-tag-more').is(":hidden")) {
// 		$(this).text(text.replace('Alle', 'Weniger'));
// 		$('.js-tag-more').slideDown('fast');
// 		setTimeout(function(){
// 			$('.js-tag-more').toggleClass('tags-vis');
// 		}, 300);
// 	} else {
// 		$(this).text(text.replace('Weniger', 'Alle'));
// 		$('.js-tag-more').removeClass('tags-vis');
// 		setTimeout(function(){
// 			$('.js-tag-more').slideUp('fast');
// 		}, 220);
// 	}
// });
var getUrl = window.location;
$("a:not([href*=" + tpdata.blog_url.replace(/\//g, '') + "])").not('[href^=\\#]').attr('target', '_blank');

$('.filter-reset').on('click', function () {
  $('.js-tag-more').removeClass('tags-vis');
  setTimeout(function () {
    $('.js-tag-more').slideUp('fast');
  }, 220);
});

var LM;
$('[data-lm-container]').each(function (index, el) {
  LM = LoadMore({
    el: el
  }).initialize()
  LM.render();
});

var $el = $('[data-posts-filter]');
if ($el.length === 1) {
  PostsFilter.initialize({
    el: $el
  })
}

setTimeout(function () {
  Lazyembed.initialize()
  window.dispatchEvent(new Event('optanon'));
}, 700);


$('[data-filter]').each(function (index, el) {
  new Filter({
    el: el,
    loadmore: LM
  }).initialize()
});

jQuery(document).ready(function () {
  $('[data-filter-typeahead]').each(function (index, el) {
    new FilterTypeahead({
      el: el,
      loadmore: LM,
      model: window.tpdata
    }).initialize()
  });

  $('[data-scroll-topic]').on('click', function (e) {
    e.preventDefault();
    let $target = $($(this).attr('href'));
    if ($target.length > 0) {
      $('html, body').animate({
        scrollTop: $target.offset().top - 200
      }, 500);
    }
  });

  $('.list-letters-filter:not(.non-interactive) a').on('click', function (e) {
    e.preventDefault();
    let $target = $($(this).attr('href'));
    if ($target.length > 0) {
      $('html, body').animate({
        scrollTop: $target.offset().top -120
      }, 500);
      window.location.hash = $target.attr('id');
    }
  });

  $('.letter-select').on('change', function (e) {
    let $target = $(e.target.value);
    if ($target.length > 0) {
      $('html, body').animate({
        scrollTop: $target.offset().top -120
      }, 500);
      window.location.hash = $target.attr('id');
    }
  });
})

// $('.parallax-bg').each(function () {
//   $(this).mouseParallax({moveFactor: 2});
// });


$(document).foundation();

var width = jQuery(window).width();
var animating = false;

if (width > 1023) {
  jQuery('.slider--one .slider-imagecontainer img').remove();
}

jQuery('.slider--one').mousemove(function (move) {
  var $slider = jQuery('.slider--one .parallax-bg');
  var moveMouse = (move.pageX * 1 / 50);
  var moveMouseY = (move.pageY * -1 / 50);
  var bgPos = $slider.css('background-position-x');
  var bgPosY = $slider.css('background-position-y');

  if (!animating && Math.abs(moveMouse - parseInt(bgPos)) > 10) {
    animating = true;
    $slider.animate({
      'background-position-x': moveMouse + 'px'
    }, 400);
    setTimeout(function () {
      animating = false;
    }, 400);
  } else if (!animating) {
    $slider.css({
      'background-position-x': moveMouse + 'px'
    });
  }

  if (!animating && Math.abs(moveMouse - parseInt(bgPosY)) > 10) {
    animating = true;
    $slider.animate({
      'background-position-y': moveMouseY + 'px'
    }, 400);
    setTimeout(function () {
      animating = false;
    }, 400);
  } else if (!animating) {
    $slider.css({
      'background-position-y': moveMouseY + 'px'
    });
  }
});



jQuery(document).ready(function () {

  window.markedItems = [];
  var $container = jQuery('body.single-post .rte-container');

  if ($container.length > 0) {
    $container.each(function (i, el) {
      new Glossar().initialize({
        el: el
      });
    })
  }
})