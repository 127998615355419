module.exports = function (options) {
  return {
    $: function (selector) {
      return jQuery(selector, this.$el);
    },
    initialize: function () {
      var that = this;
      this.$el = jQuery(options.el);
      this.model = options.model;
      this.loadmore = options.LM;
      this.isLoading = false;
      this.$parent = this.$('[data-filter-wrap]');
      // this.$tags = this.$('[data-filter-tags]').hide();
      this.$tags = this.$('[data-filter-tags]');
      this.$reset = this.$('[data-filter-reset]').hide();
      this.$input = this.$('[data-typeahead-in]');
      this.prefetch = this.$input.data('prefetch');
      this.$typeahead = this.$('#filterahead');
      this.selectedTopic = '';
      this.searchString = '';
      this.selectedEditor = this.$('[data-editor]').data('editor') || '';
      this.selectedTag = '';
      this.$currentTag = null;


      var tags = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {url: this.model.datasets_base + 'kd-tags.json', ttl: 6000, cache: false},
        identify: function (datum) {
          return datum.id;
        }
      });

      this.$input.on('keyup', function (e) {
        if (e.which == 13) {
          setTimeout(function () {
            that.searchString = that.$input.val();
            that.setTag('');
            that.setTopic('');
            that.loadMore();
          },5)
        }
      })

      jQuery('#filterahead .typeahead-in').typeahead({
          hint: true,
          highlight: true,
          minLength: 0
        },
        {
          name: 'tags',
          display: 'title',
          limit: 20,
          source: tags,
          templates: {
            notFound: function (query) {
              return '';
            },
            suggestion: function (data) {
              return '<div class="search-suggestion">' +
                '<div class="search-suggestion-name">' + data.title + '</div>' +
                '</div>';
            }
          }
        });

      // $('.typeahead-in').bind('typeahead:select', function (e, sugg) {
      //   if (sugg.type === 'kd-topic'){
      //     that.handleTopic(sugg.id)
      //   } else {
      //     that.handleTag(sugg.id)
      //   }
      // })

      $('.typeahead-in').bind('typeahead:select', function (e, sugg) {
        that.searchString = '';
        that.selectedTag = '';
        that.selectedTopic = '';
        if (sugg.type === 'kd-topic') {
          that.handleTopic(sugg.filter)
        } else {
          that.handleTag(sugg.filter)
        }
        $('.typeahead-in').typeahead('close');
        // that.$input.val(sugg.title);
        // that.$input.blur();
      })

      $('.typeahead-in').bind('typeahead:change', function (e, sugg) {
        that.selectedTag = '';
        that.selectedTopic = '';
        that.searchString = sugg;
        setTimeout(function () {
          that.loadMore();
        },5)
      })

      this.cb = options.initLoadMore;
      this.loadmore = options.loadmore;
      this.isOpen = false;
      this.renderTagToggle();
      this.loadFromQueryString();

      this.$('.tag').on('click', (e) => {
        e.preventDefault();
        this.handleTag.apply(this);
      });

      this.$('select').on('change', (e) => {
        this.handleTopic.apply(this,[e]);
      })

      this.$('.filter-reset').on('click', (e) => {
        this.reset.apply(this,[e]);
      })

      this.$('.js-tag-toggle').on('click', (e) => {
        this.toggleTags.apply(this,[e]);
      })

      this.$('.submit').on('click', (e) => {
        this.loadMore.apply(this,[e]);
      })

    },
    reset: function () {
      this.$('select').prop('selectedIndex', 0);
      this.$('.tag').removeClass('tag--one').addClass('tag--two');
      this.selectedTopic = '';
      this.setTag('');
      this.loadMore();
      if (this.loadmore) {
        this.loadmore.containerData.offset = 7;
      }
    },
    toggleTags: function (e) {
      var that = this;
      if (this.$toggleContainer.data('open')) {
        this.$toggleContainer.removeClass('tags-vis').delay(200).slideToggle(150, function () {
          that.$toggleContainer.data('open', !that.$toggleContainer.data('open'))
          that.isOpen = that.$toggleContainer.data('open');
        });
      } else {
        this.$toggleContainer.slideToggle(150, function () {
          that.$toggleContainer.addClass('tags-vis').data('open', !that.$toggleContainer.data('open'));
          that.isOpen = that.$toggleContainer.data('open');
        });
      }
      var text = $(e.currentTarget).text();
      $(e.currentTarget).text(text == "Alle Kategorien" ? "Weniger Kategorien" : "Alle Kategorien");
    },
    handleTag: function (val) {
      var that = this;
      // e.preventDefault();
      // var $el = $(e.currentTarget);
      // if (this.$currentTag) {
      //   this.$currentTag.removeClass('tag--one').addClass('tag--two');
      // }
      // this.$currentTag = $el;
      // $el.toggleClass('tag--two').toggleClass('tag--one');
      // this.setTag($el.data('tag'));
      this.setTag(val);
      this.searchString = '';
      setTimeout(function () {
        that.loadMore();
      }, 10);
    },
    setTag: function (tag) {
      this.selectedTag = tag;
      if (this.loadmore) {
        this.loadmore.setTag(tag);
      }
    },
    scrollToFirst: function () {

      if (Foundation.MediaQuery.is('small only')) {
        $('html, body').animate({scrollTop: ($('[data-tag="' + this.selectedTag + '"]').offset().top - 70)}, 'fast');
      }
    },
    renderTagToggle: function () {

      if (this.$('.tag').length < 10) {
        return;
      }

      var sliced = this.$('.tag').slice(8).detach();
      $('<div class="js-tag-more"></div><br><span class="tag-toggle js-tag-toggle">Alle Kategorien</span>').appendTo(this.$tags);
      this.$toggleContainer = this.$('.js-tag-more');
      this.$toggleTrigger = this.$('.js-tag-toggle');
      this.$toggleContainer.append(sliced);
      if (!this.isOpen) {
        this.$toggleContainer.slideUp().data('open', false);
        this.$toggleTrigger.text('Alle Kategorien')
      } else {
        this.$toggleContainer.data('open', true);
        this.$toggleContainer.addClass('tags-vis');
        this.$toggleTrigger.text('Weniger Kategorien')
      }
    },
    loadFromQueryString: function () {
      var tag = this.getParameterByName('tag');
      if (typeof tag === 'String') {
        var $el = this.$('[data-tag="' + tag + '"]');
        if (this.$currentTag) {
          this.$currentTag.removeClass('tag--one').addClass('tag--two');
        }
        this.$currentTag = $el;
        $el.toggleClass('tag--two').toggleClass('tag--one');
        this.setTag($el.data('tag'))
        this.$toggleTrigger.trigger('click');
      }

      var filter = this.getParameterByName('filter');
      if (typeof filter === 'String') {
        this.$input.val(filter);
      }

    },
    getParameterByName: function (name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
    handleTopic: function (val) {
      this.selectedTopic = val;
      this.searchString = '';
      if (this.loadmore) {
        this.loadmore.setTopic(val)
      }
      this.loadMore();
    },
    loadMore: function () {
      var that = this;
      if (this.isLoading) {
        return false;
      }
      var req = this.ajax().then(function (res) {
        that.isLoading = false;
        that.$parent.empty().append(res.out);
        that.$tags.empty().append(res.tags);
        setTimeout(function () {
          that.renderTagToggle();
          that.$('[data-tag="' + that.selectedTag + '"]').addClass('tag--one').removeClass('tag--two');
          if (that.loadmore) {
            that.loadmore.setData(res.params);
            that.loadmore.setData({hasmore: res.postsLeft});
            that.loadmore.setElement(that.$('[data-lm-container]').eq(0));
            setTimeout(function () {
              that.loadmore.render();
              that.scrollToFirst();
            }, 10);
            if ($(window).width() < 640) {
              $('html, body').animate({scrollTop: ($('[data-tag="' + that.selectedTag + '"]').offset().top - 70)}, 'fast');
            }
          }
        }, 10);
      })
    },
    ajax: function () {
      var getUrl = window.location;
      var baseUrl = window.tpdata.base_url + "kd/v1/filter/";
      var that = this;
      var data = {
        'topic': this.selectedTopic,
        'tag': this.selectedTag,
        'editor': this.selectedEditor,
        'q': this.searchString
      };
      return jQuery.ajax({
        url: baseUrl,
        beforeSend: function () {
          that.isLoading = true;
        },
        data: data
      })
    }
  }
};